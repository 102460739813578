import React from 'react';
import { Grid, Button, Icon, Image } from 'semantic-ui-react';

class Content extends React.Component {
    render() {
        return(                
                <div id='layout-content'>                     
                    <Grid id="about" columns={2} stackable>
                        <Grid.Column>                            
                            <div id='welcome'>                                
                                <p id='welcome-message'>Welcome to the Personal<br/>Page of Kelun Ouyang</p>
                                <p id="tldr" className='left'>My name is Kelun Ouyang. While I am a developer by trade, my passion lies in learning more about myself and what makes me tick. My other major hobbies, gaming and weightlifting, reflect that passion.</p>
                                <p id='contact' className='left'>Feel free to get in touch with me via LinkedIn for a full copy of my resume!</p>
                                <div id='social-media'> 
                                    <Image id='badtzmaru' src='/images/badtzmaru.png' />
                                    <Button as='a' href='https://www.linkedin.com/in/kelun-ouyang-26315527/' target='_blank' color='linkedin'>
                                        <Icon name='linkedin' /> LinkedIn
                                    </Button>
                                </div>                                   
                            </div>                            
                        </Grid.Column>
                        <Grid.Column>
                            <div id='hobbies'>
                                <div id='developer'>
                                    <h2>Developer</h2>
                                    <p className='left'>
                                        My main habitat is within the Microsoft Safari of DOT NET and C#. I have worked on the front end using HTML and JQuery. My database toolset experience includes MSSQL using TSQL and MySQL using SQL. My current goal is learning and becoming more apt at using React.
                                    </p>
                                </div>
                                <div id='gamer'>
                                    <h2>Gamer</h2>
                                    <p className='left'>
                                        I have been gaming since I was in elementary school. I believe gaming had a lot to do with teaching me how to problem solve. Each time I would fail at a boss fight I would learn a bit about myself and refine how I would approach a problem. In addition, since I also played social games, it was my first avenue of socializing.
                                    </p>
                                </div>
                                <div id='weightlifter'>
                                    <h2>Weightlifter</h2>
                                    <p className='left'>
                                        Weightlifting is an amazing thing since you have to not only be in tune with your body, but also your mind. To me, it's a reflection of all the hard work I put in during a programming cycle when you hit a personal record. If I fail, I still learn from what I had done wrong and move onto the next programming cycle.
                                    </p>
                                </div>
                            </div>                                                    
                        </Grid.Column>
                    </Grid>
                </div>
        );
    }
}

export default Content;