import React from 'react';
import { Image } from 'semantic-ui-react'

class Top extends React.Component {
    render() {
        return(
            <div id='layout-top'>
                <Image src='/images/logo.png' id='logo' alt='logo'/>
            </div>
        );
    }
}

export default Top;