import React from 'react';
import ReactDOM from 'react-dom';

// Layout
import Top from './components/layout/Top';
import Content from './components/layout/Content';

// Style
import 'semantic-ui-less/semantic.less';
import './components/layout/main.css';

class HomePage extends React.Component {
    render () {
        return (
            <div id="view-body">
                <div id="background-image-container">
                    <Top />
                    <Content />
                </div>
            </div>
        );
    }
};

ReactDOM.render(
    <HomePage />, document.querySelector('#root')
);